<template>
  <div class="page">
    <div class="page-header">
      <a-form-model
        class="ant-advanced-search-form"
        :labelCol="{span: 8}"
        :wrapperCol="{span: 16}"
        :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-input-group compact  style="margin-top: 6px;">
                <a-select  style="width: 110px;" v-model="selectedOption" @select="handleChange" default-value="mobileNo">
                  <a-select-option  value="mobileNo">
                    手机号
                  </a-select-option>
                  <a-select-option value="name">
                    姓名
                  </a-select-option>
                  <a-select-option value="idNo">
                    身份证号
                  </a-select-option>
                  <a-select-option value="applyNo">
                    授信流水号
                  </a-select-option>
                  <a-select-option value="channelApplyNo">
                    渠道订单号
                  </a-select-option>
                </a-select>
                <a-input style="width: 61%"  @input="handleInput"  :placeholder="inputPlaceholder" v-model="inputValue"/>
            </a-input-group>
          </a-col>
          <a-col  :span="6">
             <a-form-model-item label="渠道方编号:" prop="channelCode">
              <a-select placeholder="请选择" v-model="form.channelCode" :allowClear=true  >
                  <a-select-option v-for="(item) in channelCodeList" :key="item">
                  {{  item }}
                  </a-select-option>
              </a-select>
             </a-form-model-item>
          </a-col>
          <a-col  :span="6">
            <a-form-model-item label="资产方编号:" prop="fundCode">
                <a-select placeholder="请选择"  v-model="form.fundCode" :allowClear=true>
                  <a-select-option v-for="(item ) in fundCodeList" :key="item">
                    {{ item }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="授信状态:" prop="applyStatus">
                <a-select  :allowClear=true v-model="form.applyStatus"  placeholder="请选择" >
                    <a-select-option v-for="(item) in statusList" :key="item.key">
                      {{  item.value}}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="6">
            <a-form-model-item label="授信申请时间:" prop="applyTime">
              <a-date-picker  placeholder="请选择" v-model="form.applyTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"   />
            </a-form-model-item>
          </a-col>
          <a-col :span="18" style="text-align: right;" >
              <a-button type="primary" html-type="submit" style="margin-right: 10px;">
                搜索
              </a-button>
              <a-button  html-type="submit" @click="handleReset">
                清空
              </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-row class="page-content">
      <a-col :span="24">
        <a-table
          :scroll="{ x: 2500 }"
          :columns="columns"
          :data-source="data"
          :loading="loading"
          :pagination="pagination"
          :row-key="record => record.id"
          @change="getPage"
        >
        <span slot="status" slot-scope="text">{{ text ==='SUCCESS' ? '成功' :  text ==='FAIL' ? '失败' : '申请中' }}</span>
        <span slot="deviceInfo" slot-scope="text, record">
          <a @click="showInfo(record, 'deviceInfo')">查看</a>
        </span>
        <span slot="userInfo" slot-scope="text, record">
          <a @click="showInfo(record, 'baseInfo')">查看</a>
        </span>
        </a-table>
      </a-col>
    </a-row>
    <a-modal
      v-model="detailing"
      title="查看信息"
      @ok="() => detailing = false"
      okText="关闭"
      :bodyStyle="{maxHeight: '400px', 'overflow': 'scroll'}"
      :cancelButtonProps="{style: { display: 'none' }}"
      >
       <pre>{{ detailInfo }}</pre>
    </a-modal>
  </div>
</template>

<script>
import { creditApplyList } from '@/api/credit';

const columns = [
  {
    title: '授信流水号',
    dataIndex: 'applyNo',
    key: 'applyNo',
  },
  {
    title: '渠道方编码',
    dataIndex: 'channelCode',
    key: 'channelCode',
    width: 110,
  },
  {
    title: '渠道方流水号',
    dataIndex: 'channelApplyNo',
    key: 'channelApplyNo',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '失败原因',
    dataIndex: 'failReason',
    key: 'failReason',
  },
  {
    title: '资金方编码',
    key: 'fundCode',
    dataIndex: 'fundCode',
  },
  {
    title: '资金方流水号',
    key: 'fundApplyNo',
    dataIndex: 'fundApplyNo',
  },
  {
    title: '授信申请时间',
    key: 'applyTime',
    dataIndex: 'applyTime',
  },
  {
    title: '授信成功时间',
    key: 'successTime',
    dataIndex: 'successTime',
  },
  {
    title: '手机号',
    key: 'mobileNo',
    dataIndex: 'mobileNo',
  },
  {
    title: '身份证号',
    key: 'idNo',
    dataIndex: 'idNo',
  },
  {
    title: '姓名',
    key: 'name',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: '授信金额',
    key: 'grantAmountStr',
    dataIndex: 'grantAmountStr',
  },
  {
    title: '授信利率',
    key: 'creditRate',
    dataIndex: 'creditRate',
  },
  {
    title: '利率类型',
    key: 'creditRateType',
    dataIndex: 'creditRateType',
  },
  // {
  //   title: '设备信息',
  //   key: 'deviceInfo',
  //   scopedSlots: { customRender: 'deviceInfo' },
  // },
  // {
  //   title: '用户基本信息',
  //   key: 'userInfo',
  //   scopedSlots: { customRender: 'userInfo' },
  // },
];

export default {
  name: 'Home',
  data() {
    return {
      channelCodeList: ['XINRENPAI'],
      fundCodeList: ['NIWODAI'],
      statusList: [{ key: 'SUCCESS', value: '成功' }, { key: 'FAIL', value: '失败' }, { key: 'APPLYING', value: '申请中' }],
      selectedOption: 'mobileNo',
      inputValue: '',
      inputPlaceholder: '请输入手机号',
      data: [],
      columns,
      expand: false,
      form: {
        mobileNo: '',
        name: '',
        idNo: '',
        applyNo: '',
        channelApplyNo: '',
        applyTime: '',
        channelCode: undefined,
        fundCode: undefined,
        applyStatus: undefined,
      },
      detailInfo: '',
      detailing: false,
      loading: false,
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    handleInput(event) {
      this.inputValue = event.target.value.trim();
    },
    handleReset() {
      this.inputValue = '';
      this.form = {
        mobileNo: '',
        name: '',
        idNo: '',
        applyNo: '',
        channelApplyNo: '',
        applyTime: '',
        channelCode: undefined,
        fundCode: undefined,
        applyStatus: undefined,
      };
      this.fetchPage();
    },
    handleChange(value) {
      this.inputValue = '';
      this.form.name = '';
      this.form.idNo = '';
      this.form.applyNo = '';
      this.form.channelApplyNo = '';
      this.form.mobileNo = '';

      this.inputPlaceholder = {
        name: '请输入姓名',
        idNo: '请输入身份证号',
        applyNo: '请输入授信流水号',
        channelApplyNo: '请输入渠道方订单号',
        mobileNo: '请输入手机号',
      }[value];
    },
    showInfo(record, type) {
      this.detailInfo = JSON.stringify(JSON.parse(record[type] || ''), null, 2);
      this.detailing = true;
    },
    handleSearch(e) {
      e.preventDefault();
      const selectedValue = this.selectedOption;
      const formKey = {
        name: 'name',
        idNo: 'idNo',
        applyNo: 'applyNo',
        channelApplyNo: 'channelApplyNo',
        mobileNo: 'mobileNo',
      }[selectedValue];
      this.form[formKey] = this.inputValue;
      this.pagination.current = 1;
      this.fetchPage();
    },
    async fetchPage() {
      this.loading = true;
      const { result, success, errorMsg } = await creditApplyList({
        ...this.form,
        limit: 10,
        offset: this.pagination.current,
      });
      this.loading = false;
      if (!success) {
        this.$message.error(errorMsg);
        return;
      }

      this.data = result.authApplyFlowList;
      this.pagination.total = result.total;
    },
    getPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchPage();
    },
  },
  mounted() {
    this.fetchPage();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>
